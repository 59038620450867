import React, {useEffect} from 'react';

const FlipBook = () => {
  useEffect(() => {
    document.title = `flipBook`;
    getInitialData();
  }, []);


  const getInitialData = async () => {
    window.open("flipbook.pdf", '_self');  
  };
 
  return (
    <div>
    </div>
  );
};

export default FlipBook;
