import React from 'react';
import FlipBook from './pages/FlipBook';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const App = (props) => {
  return (
    <div>
      <Router>
        <Switch>
        <Route exact path='/flipbook.pdf' component={FlipBook } />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
